<template>
  <b-card class="mb-4 pt-4" header-tag="header">
    <template #header>
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">{{ $route.name }}</h5>
        </b-col>
       
      </b-row>
    </template>
    <b-row class="mb-3" style="margin-top: -20px;"> </b-row>
    <el-table
      stripe
      :data="data"
      border
      v-if="!isLoading"
      style="width: 100%;"
      empty-text="Data tidak ditemukan"
    >
      <el-table-column type="index" label="No" align="center" fixed />
      <el-table-column prop="amount" align="right" label="Amount">
        <template #default="scope">{{ formatCurrency(scope.row.amount) }}</template>
      </el-table-column>
      <el-table-column align="center" label="installments">
        <el-table-column prop="month_12" align="right" label="12 Bulan">
          <template #default="scope">{{ formatCurrency(scope.row.installments[0].amount_monthly) }}</template>
        </el-table-column>
        <el-table-column prop="month_24" align="right" label="24 Bulan">
          <template #default="scope">{{ formatCurrency(scope.row.installments[1].amount_monthly) }}</template>
        </el-table-column>
        <el-table-column prop="month_36" align="right" label="36 Bulan">
          <template #default="scope">{{ formatCurrency(scope.row.installments[2].amount_monthly) }}</template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </b-card>
</template>

<script>
import { formatModelValue } from '@/_helpers'
import Bailout from "@/models/Bailout.js";

const BailoutModel = new Bailout();

export default {
  components: {
  },
  data() {
    return {
      isLoading: false,
      data: null
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    formatCurrency(value) {
      return formatModelValue("price", value);
    },
    getListData: function() {
      BailoutModel.list().then(resp => {
        this.data = resp.data
      })
    }
  },
};
</script>
